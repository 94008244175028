import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import { detect } from "detect-browser";
import Main from "./main";

const browser = detect();

// Only setup PWA in production
if (process.env.APP_ENV === "production") {
  import("offline-plugin/runtime").then(({ default: OfflinePluginRuntime }) => {
    OfflinePluginRuntime.install({
      onUpdateReady() {
        OfflinePluginRuntime.applyUpdate();
      },
      onUpdated() {
        window.location.reload();
      },
    });
  });
}

render(<Main browser={browser} />, document.querySelector("#root-app"));
