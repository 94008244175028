import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";

const MOBILE_WIDTH = 1000;

const getPlatform = (browser) => {
  if (browser.name === "ie" && parseInt(browser.version) <= 11) {
    return "mobile";
  }
  return window.innerWidth <= MOBILE_WIDTH ? "mobile" : "desktop";
};

const PLATFORM_COMPONENTS = {
  desktop: lazy(() => import("./desktop/components/App/index")),
  mobile: lazy(() => import("./mobile/components/App/index")),
};

export default function Main({ browser = {}, ...props }) {
  const [component, setComponent] = useState(() => getPlatform(browser));

  const handleResize = useCallback(() => {
    const platform = getPlatform(browser);
    if (platform === "desktop") {
      document.body.classList.remove("mobile");
      document.body.classList.add("desktop");
    } else {
      document.body.classList.remove("desktop");
      document.body.classList.add("mobile");
    }
    setComponent(platform);
  }, [setComponent]);

  useEffect(() => {
    document.body.classList.add(browser.name);
    document.body.classList.add(`${browser.name}-${parseInt(browser.version)}`);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const Component = PLATFORM_COMPONENTS[component];

  return (
    <Suspense fallback="Loading...">
      <Component browser={browser} {...props} />
    </Suspense>
  );
}
